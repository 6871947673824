.ModelPage_TextSimple {
    width: 100%;
    height: auto;
    padding: 80px 0px;
    background-color: #F5F5F5;
    font-family: 'montserrat';
    transition: 0.5s;
    position: relative;
}

.ModelPage_TextSimple .color_1{
    color: #6B7D5C;
}
.ModelPage_TextSimple .color_2{
    color: #F5F5F5;
}

.ModelPage_TextSimple .space_top{
    margin-top: 40px;
}

.ModelPage_TextSimple .container {
    width: calc(100% - 80px);
    height: auto;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
}
.ModelPage_TextSimple .title{
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
}
.ModelPage_TextSimple .text{
    text-align: left;
    font-size: 14px;
    line-height: 20px;
}

@media only screen and (max-width: 820px) {
     .ModelPage_TextSimple .container {
        width: calc(86% - 60px);
    }
}
@media only screen and (max-width: 690px) {
    .ModelPage_TextSimple .container{
        width: calc(100% - 80px);
    }
}
@media only screen and (max-width: 580px) {
    .ModelPage_TextSimple .container{
        width: calc(100% - 40px);
    }
    .ModelPage_TextSimple .title{
        font-size: 24px;
        line-height: 24px;
    }
}
@media only screen and (max-width: 460px) {
    .ModelPage_TextSimple .container{
        align-items: center;
    }
}