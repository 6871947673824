.PopUp .health{
    width: 800px;
    font-family: 'inter';
    margin-bottom: 40px;
}
.PopUp .health .list_data_input{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
    position: relative;
    padding: 10px 0px 0px;
}
.PopUp .health .list_data_input:nth-child(n+2) {
    padding-top: 20px;
}
.PopUp .health .list_data_input .div_input{
    width: auto;
    position: relative;
}
.PopUp .health .list_data_input .div_input input, .PopUp .health .list_data_input .div_input select{
    font-size: 14px;
    padding: 10px;
    display: block;
    width: calc(100% - 20px);
    border: 1px solid #324d6b;
    border-radius: 4px;
    background: transparent;
    color: #000000;
    font-family: 'inter';
    outline: none;
}
.PopUp .health .list_data_input .div_input select, .PopUp .health .list_data_input .div_input input[type="date"]{
    padding: 9px;
}
.PopUp .health .list_data_input .div_input .input_date{
    width: 290px;
}
.PopUp .health .list_data_input .div_input .textarea{
    width: calc(100% - 20px);
    resize: none;
    font-size: 14px;
    padding: 10px;
    color: #000000;
    border: 1px solid #324d6b;
    min-height: 40px;
    outline: none;
    border-radius: 4px;
}
.PopUp .health .list_data_input .div_input .name_input{
    position: absolute;
    top: -12px;
    left: 10px;
    background-color: #ffffff;
    padding: 0px 10px;
}
.PopUp .health .list_data_input .div_input .contact{
    width: 180px;
}
.PopUp .health .list_data_input .div_input .list_hour{
    width: 160px;
}
.PopUp .health .list_data_input .error_date{
    color: #f00000;
}

.PopUp .health .show_data{
    width: 100%;
}
.PopUp .health .show_data .div_btn{
    width: 100%;
}
.PopUp .health .show_data .btn{
    width: calc(100% - 40px);
    cursor: pointer;
    font-size: 14px;
    color: #E7E3CE;
    background-color: rgb(107, 125, 92);
    padding: 10px 20px;
    border-radius: 10px;
    border: transparent;
    margin-top: 30px;
}
.PopUp .health .show_data .btn:hover{
    background-color: rgb(89, 104, 75);
}

.PopUp .health .show_data .btn_save{
    font-size: 15px;
    font-family: 'Montserrat';
    padding: 11px 20px;
    width: 100%;
    margin-top: 0;
}

@media only screen and (max-width: 860px) {
    .PopUp .health {
        width: 90%;
    }
    .PopUp .health .popup_text{
        font-size: 12px;
    }
    .PopUp .health .list_data_input .popup_text{
        text-align: left;
    }
    .PopUp .health .list_data_input .div_input .textarea{
        font-size: 12px;
    }
    .PopUp .health .list_data_input .div_input .name_input{
        font-size: 12px;
    }
}
@media only screen and (max-width: 850px) {
    .PopUp .health .popup_text{
        font-size: 10px;
    }
}
@media only screen and (max-width: 780px) {
    .PopUp .health .list_data_input{
        flex-direction: column;
        gap: 30px;
    }
    .PopUp .health .list_data_input:nth-child(n+2){
        padding-top: 30px;
    }
    .PopUp .health .list_data_input .div_input, 
    .PopUp .health .list_data_input .div_input .input_date,
    .PopUp .health .list_data_input .div_input .list_hour{
        width: 100%;
    }
    .PopUp .health .list_data_input .div_input .contact{
        width: calc(100% - 20px);
    }
}