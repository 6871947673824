.Menu{
    width: 100%;
    height: 160px;
    position: absolute;
    transition: 0.5s;
    color: #E7E3CE;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    z-index: 10;
}
.Menu .div_contact{
    font-size: 12px;
    width: 270px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Menu .div_logo{
    width: 270px;
    height: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;
}
.Menu .div_logo .logo{
    width: auto;
    height: 80%;
}
.Menu .div_opt{
    width: 270px;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}
.Menu .div_idioma{
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
}
.Menu .div_idioma .list_opt{
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;

    top: 30px;    
    overflow-y: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-out;
}

@media only screen and (max-width: 760px) {
    .Menu{
        width: calc(100% - 40px);
        padding: 0px 20px;
    }
    .Menu .div_contact{
        font-size: 10px;
        text-align: center;
    }
    .Menu .div_logo .logo{
        height: 60%;
    }
}