.ModelPage_CardPhoto {
    width: 100%;
    height: auto;
    padding: 80px 0px;
    background-color: #F5F5F5;
    font-family: 'montserrat';
    transition: 0.5s;
    position: relative;
}

.ModelPage_CardPhoto .container {
    width: calc(100% - 80px);
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 60px;
    transition: 0.5s;
}

.ModelPage_CardPhoto .card_image{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    width: 46%;
    height: 600px;
    position: relative;
}
.ModelPage_CardPhoto .card_image .bg_color{
    background-color: rgb(24 53 0);
    width: 100%;
    height: 100%;
    opacity: 0.6;
    border-radius: 10px;
}
.ModelPage_CardPhoto .card_image .div_data{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: #E7E3CE;
}
.ModelPage_CardPhoto .card_image .data_left {
    align-items: flex-start;
}
.ModelPage_CardPhoto .card_image .data_right {
    align-items: flex-end;
}
.ModelPage_CardPhoto .card_image .div_data .show_data{
    width: calc(70% - 60px);
    height: 100%;
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    transition: 0.5s;
}
.ModelPage_CardPhoto .card_image .div_data .title{
    font-size: 26px;
    line-height: 30px;
    font-weight: 900;
}
.ModelPage_CardPhoto .card_image .div_data .text{
    font-size: 12px;
}
.ModelPage_CardPhoto .card_image .div_data .btn{
    text-align: center;
    width: calc(100% - 40px);
    color: #6B7D5C;
    background-color: #E7E3CE;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 900;
}
.ModelPage_CardPhoto .card_image .div_data .left {
    text-align: left;
}
.ModelPage_CardPhoto .card_image .div_data .right {
    text-align: right;
}

@media only screen and (max-width: 1025px) {
    .ModelPage_CardPhoto .card_image .div_data .show_data {
        width: calc(88% - 60px);
    }
}
@media only screen and (max-width: 820px) {
    .ModelPage_CardPhoto .card_image .div_data .show_data {
        width: calc(100% - 60px);
    }
    .ModelPage_CardPhoto .card_image .div_data .right{
        text-align: center;
    }
}
@media only screen and (max-width: 730px) {
    .ModelPage_CardPhoto .container{
        flex-direction: column;
        gap: 30px;
    }
    .ModelPage_CardPhoto .card_image{
        width: 100%;
    }
    .ModelPage_CardPhoto .card_image .div_data .text{
        font-size: 14px;
    }
}
@media only screen and (max-width: 600px) {
    .ModelPage_CardPhoto .container{
        width: calc(100% - 40px);
    }
    .ModelPage_CardPhoto .card_image .div_data .text{
        font-size: 12px;
    }
    .ModelPage_CardPhoto .card_image .div_data .show_data{
        height: calc(100% - 20px);
        padding: 10px 30px;
        justify-content: center;
        gap: 10px;
    }
}
@media only screen and (max-width: 580px) {
    .ModelPage_CardPhoto .card_image .div_data .title{
        font-size: 18px;
        line-height: 22px;
    }
    .ModelPage_CardPhoto .card_image .div_data .btn{
        font-size: 14px;
    }
}