.ModelPage_Questions{
    width: 100%;
    height: auto;
    padding: 80px 0px;
    background-color: #E7E3CE;
    font-family: 'montserrat';
    transition: 0.5s;
    position: relative;
}
.ModelPage_Questions .sheet{
    position: absolute;
    transition: 0.5s;
}
.ModelPage_Questions .sheet_1{
    top: -50px;
    left: 0;
    width: 250px;
}
.ModelPage_Questions .sheet_2{
    right: 0;
    top: 180px;
    width: 140px;
}
.ModelPage_Questions .sheet_3{
    bottom: -28px;
    left: 0;
    width: 180px;
}
.ModelPage_Questions .sheet_img{
    width: 100%;
    height: auto;
}

.ModelPage_Questions .container {
    width: calc(80% - 80px);
    height: auto;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
}
.ModelPage_Questions .title{
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    color: #6B7D5C;
    text-align: center;
}
.ModelPage_Questions .list_questions{
    text-align: center;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.ModelPage_Questions .list_questions .div_questions{
}
.ModelPage_Questions .list_questions .div_questions .icon_open{
    transform: rotate(90deg);
}
.ModelPage_Questions .list_questions .div_questions .ques_title{
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    gap: 10px;
}
.ModelPage_Questions .list_questions .div_questions .ques_title:hover{
    text-shadow: #000 0px 0px 6px;
}
.ModelPage_Questions .list_questions .div_questions .ques_text{
    font-size: 14px;
    overflow-y: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-out;
    text-align: left;
}
.ModelPage_Questions .list_questions .div_btn{
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    cursor: pointer;
}
.ModelPage_Questions .list_questions .div_btn .btn{
    background-color: #6B7D5C;
    padding: 10px 20px;
    border-radius: 6px;
    color: #E7E3CE;
}

@media only screen and (max-width: 1000px) {
    .ModelPage_Questions .sheet_1{
        width: 200px;
    }
}
@media only screen and (max-width: 820px) {
    .ModelPage_Questions .container{
        width: calc(86% - 60px);
    }
    .ModelPage_Questions .sheet_1{
        width: 200px;
        top: -18px;
    }
    .ModelPage_Questions .sheet_2{
        width: 100px;
    }
    .ModelPage_Questions .sheet_3{
        width: 140px;
    }
}
@media only screen and (max-width: 690px) {
    .ModelPage_Questions .container{
        width: calc(100% - 80px);
    }
    .ModelPage_Questions .sheet_1{
        width: 140px;
    }
    .ModelPage_Questions .sheet_2{
        display: none;
    }
    .ModelPage_Questions .sheet_3{
        width: 100px;
    }
}
@media only screen and (max-width: 580px) {
    .ModelPage_Questions .container{
        width: calc(100% - 40px);
    }
    .ModelPage_Questions .title{
        font-size: 24px;
        line-height: 24px;
    }
    .ModelPage_Questions .list_questions .div_questions .ques_title{
        font-size: 14px;
    }
    .ModelPage_Questions .list_questions .div_questions .ques_text{
        font-size: 12px;
    }
}
@media only screen and (max-width: 460px) {
    .ModelPage_Questions .container{
        align-items: center;
    }
    .ModelPage_Questions .title{
        width: min-content;
    }
}