.ModelPage_CardIcons {
    width: 100%;
    height: auto;
    padding: 80px 0px;
    color: #E7E3CE;
    background-color: #F5F5F5;
    font-family: 'montserrat';
    transition: 0.5s;
    position: relative;
}

.ModelPage_CardIcons .container {
    width: calc(100% - 80px);
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    transition: 0.5s;
    position: relative;
    z-index: 2;
    gap: 40px;
}

.ModelPage_CardIcons .card_image{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 180px;
    min-width: 180px;
    height: 200px;
    min-height: 200px;
    border-radius: 10px;
    background: #6B7D5C;
    padding: 0px 10px;

    transition: 0.5s;
}
.ModelPage_CardIcons .card_image .div_img{
    width: auto;
    height: 60px;
    padding: 10px 0;
}
.ModelPage_CardIcons .card_image .div_img .img{
    width: auto;
    height: 100%;
    display: flex;
}
.ModelPage_CardIcons .card_image .text{
    text-align: center;
    font-size: 12px;
    flex-grow: 1;
}

@media only screen and (max-width: 600px) {
    .ModelPage_CardIcons .container{
        width: calc(100% - 40px);
        gap: 20px;
    }
}

@media only screen and (max-width: 486px) {
    .ModelPage_CardIcons .card_image{
        width: 100%;
        min-width: 180px;
        height: auto;
        min-height: auto;
        padding: 30px 0px;
    }
    .ModelPage_CardIcons .card_image .div_img{
        padding: 0;
        padding-bottom: 10px;
    }
}