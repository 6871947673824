footer{
    width: 100%;
    height: 160px;
    background-color: #6B7D5C;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 20px;

    transition: 0.5s;
}
footer .div_logo{
    width: 100px;
    height: auto;
}
footer .div_logo .logo{
    width: 100%;
    height: auto;
    display: flex;
}
footer .div_text{
    color: #E7E3CE;
    font-family: 'montserrat';
    cursor: pointer;
}
footer .div_ers{
    width: 160px;
    height: auto;
}
footer .div_ers .ers{
    width: 100%;
    height: auto;
    display: flex;
}

@media only screen and (max-width: 850px) {
    footer .div_logo{
        width: 60px;
    }
    footer .div_text{
        font-size: 12px;
        text-align: center;
    }
    footer .div_ers{
        width: 100px;
    }
}
@media only screen and (max-width: 530px) {
    footer{
        flex-direction: column;
        gap: 40px;
        height: auto;
        padding: 40px 0px;
    }
}