html {
    scroll-behavior: smooth;
}
body, html {
    height: 100%;
}
body {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #6B7D5C;
    font-family: 'Poppins';
    background-color: #fff;
    overflow-x: hidden;
    letter-spacing: 1px;
}
a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}
ul {
    margin: 0px;
    padding: 0px;
}
p{
    padding: 0px;
    margin: 0;
}
svg {
    transition: all .2s cubic-bezier(.4, 0, 1, 1) 0s;
    cursor: pointer;
}
#root {
    width: 100%;
    height: 100%;
    position: relative;
}
.container{
    max-width: 1140px;
    margin: 0% auto;
    padding: 0px 40px;
}
.icons {
    width: 24px;
    height: 24px;
    display: block;
}
.title_page{
    font-family: 'Bebas Neue';
    font-size: 40px;
    margin-bottom: 16px;
}
.space_div{
    flex-grow: 1;
}


/* scrollbar */
::-webkit-scrollbar {
    height: 10px;
    width: 4px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0px 14px rgb(107 125 92 / 50%);
    border-radius: 6px;
}
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0px 14px rgb(107 125 92);
    border-radius: 6px;
}
/* end */


/* pop up */
.PopUp {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 8000;
    position: fixed;
    margin: 0% auto;
    overflow-y: auto;
    background: #FFF;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 50%);
    font-family: 'Inter';
}
.PopUp .all {
    width: 600px;
    height: auto;
    display: flex;
    text-align: center;
    margin: 2em auto 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    overflow-y: hidden;
    position: relative;
    border-radius: 6px;
    z-index: 30;
}
.PopUp .close_all{
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    z-index: 20;
    top: 0;
}
.PopUp .div_data {
    display: flex;
    text-align: center;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    padding: 20px;
}
.PopUp .type_div{
    border-bottom: 2px solid #324d6b;
    padding: 16px 20px 0px;
    margin-bottom: 20px;
    align-items: center;
}
.PopUp .popup_title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
}
.PopUp .close {
    display: flex;
    padding-bottom: 10px;
    height: fit-content;
}
.PopUp .content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.PopUp .div_input{
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.PopUp .div_contents {
    background-color: #ffffff;
    padding: 16px;
}
.PopUp .button {
    padding: 0px 20px 20px;
}
.PopUp .button_save {
    background-color: #76c57e;
    padding: 4px 20px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .register_data{
    background-color: #7c9f06;
    width: auto;
    padding: 6px 10px;
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 16px;
}
.PopUp .opt_select{
    cursor: pointer;
    width: 120px;
    color: #ffffff;
}
.PopUp .yes_update {
    background-color: #76c57e;
    padding: 6px 10px;
    border-radius: 8px;
}
.PopUp .not_update {
    background-color: #e95656;
    padding: 6px 10px;
    border-radius: 8px;
}
/* end */

/* whatsapp fixed right */
#return{
    transition: 1s;
    position: fixed;
    bottom: 82px;
    right: 25px;
    z-index: 999;
    display: flex;
}
.return_close {
    opacity: 0;
}
.return_index{
    opacity: 1;
}

.whatsapp a{
    display: flex;
}
.whatsapp{
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 999;
}
.icon_whatsapp{
    width: 50px;
    height: 50px;
}
/* end */


@media only screen and (max-width: 580px) {
    .container{
        padding: 0px 16px;
    }
}


/* loading */
.return_data_save{
    position: fixed;
    left: 0;
    top: 0;
    background: rgb(0 0 0 / 80%);
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
}
.div_loading{
    display: flex;
}
.hourglassBackground {
    position: relative;
    background-color: #002d6a;
    height: 130px;
    width: 130px;
    border-radius: 50%;
    margin: 30px auto;
}
.hourglassContainer {
    position: absolute;
    top: 30px;
    left: 40px;
    width: 50px;
    height: 70px;
    -webkit-animation: hourglassRotate 2s ease-in 0s infinite;
    animation: hourglassRotate 2s ease-in 0s infinite;
    transform-style: preserve-3d;
    perspective: 1000px;
}
.hourglassContainer div, .hourglassContainer div:before, .hourglassContainer div:after {
    transform-style: preserve-3d;
}
@-webkit-keyframes hourglassRotate {
    0% {
        transform: rotateX(0deg);
    }
    50% {
        transform: rotateX(180deg);
    }
    100% {
        transform: rotateX(180deg);
    }
}
@keyframes hourglassRotate {
    0% {
        transform: rotateX(0deg);
    }
    50% {
        transform: rotateX(180deg);
    }
    100% {
        transform: rotateX(180deg);
    }
}
.hourglassCapTop {
    top: 0;
}
.hourglassCapTop:before {
    top: -25px;
}
.hourglassCapTop:after {
    top: -20px;
}
.hourglassCapBottom {
    bottom: 0;
}
.hourglassCapBottom:before {
    bottom: -25px;
}
.hourglassCapBottom:after {
    bottom: -20px;
}
.hourglassGlassTop {
    transform: rotateX(90deg);
    position: absolute;
    top: -16px;
    left: 3px;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    background-color: #999999;
}
.hourglassGlass {
    perspective: 100px;
    position: absolute;
    top: 32px;
    left: 20px;
    width: 10px;
    height: 6px;
    background-color: #999999;
    opacity: 0.5;
}
.hourglassGlass:before, .hourglassGlass:after {
    content: '';
    display: block;
    position: absolute;
    background-color: #999999;
    left: -17px;
    width: 44px;
    height: 28px;
}
.hourglassGlass:before {
    top: -27px;
    border-radius: 0 0 25px 25px;
}
.hourglassGlass:after {
    bottom: -27px;
    border-radius: 25px 25px 0 0;
}
.hourglassCurves:before, .hourglassCurves:after {
    content: '';
    display: block;
    position: absolute;
    top: 32px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #333;
    animation: hideCurves 2s ease-in 0s infinite;
}
.hourglassCurves:before {
    left: 15px;
}
.hourglassCurves:after {
    left: 29px;
}
@-webkit-keyframes hideCurves {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
@keyframes hideCurves {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
.hourglassSandStream:before {
    content: '';
    display: block;
    position: absolute;
    left: 24px;
    width: 3px;
    background-color: white;
    -webkit-animation: sandStream1 2s ease-in 0s infinite;
    animation: sandStream1 2s ease-in 0s infinite;
}
.hourglassSandStream:after {
    content: '';
    display: block;
    position: absolute;
    top: 36px;
    left: 19px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    animation: sandStream2 2s ease-in 0s infinite;
}
@-webkit-keyframes sandStream1 {
    0% {
        height: 0;
        top: 35px;
    }
    50% {
        height: 0;
        top: 45px;
    }
    60% {
        height: 35px;
        top: 8px;
    }
    85% {
        height: 35px;
        top: 8px;
    }
    100% {
        height: 0;
        top: 8px;
    }
}

@keyframes sandStream1 {
    0% {
        height: 0;
        top: 35px;
    }
    50% {
        height: 0;
        top: 45px;
    }
    60% {
        height: 35px;
        top: 8px;
    }
    85% {
        height: 35px;
        top: 8px;
    }
    100% {
        height: 0;
        top: 8px;
    }
}

@-webkit-keyframes sandStream2 {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    51% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    91% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes sandStream2 {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    51% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    91% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.hourglassSand:before, .hourglassSand:after {
    content: '';
    display: block;
    position: absolute;
    left: 6px;
    background-color: white;
    perspective: 500px;
}
.hourglassSand:before {
    top: 8px;
    width: 39px;
    border-radius: 3px 3px 30px 30px;
    animation: sandFillup 2s ease-in 0s infinite;
}
.hourglassSand:after {
    border-radius: 30px 30px 3px 3px;
    animation: sandDeplete 2s ease-in 0s infinite;
}
@-webkit-keyframes sandFillup {
    0% {
        opacity: 0;
        height: 0;
    }
    60% {
        opacity: 1;
        height: 0;
    }
    100% {
        opacity: 1;
        height: 17px;
    }
}
@keyframes sandFillup {
    0% {
        opacity: 0;
        height: 0;
    }
    60% {
        opacity: 1;
        height: 0;
    }
    100% {
        opacity: 1;
        height: 17px;
    }
}

@-webkit-keyframes sandDeplete {
    0% {
        opacity: 0;
        top: 45px;
        height: 17px;
        width: 38px;
        left: 6px;
    }
    1% {
        opacity: 1;
        top: 45px;
        height: 17px;
        width: 38px;
        left: 6px;
    }
    24% {
        opacity: 1;
        top: 45px;
        height: 17px;
        width: 38px;
        left: 6px;
    }
    25% {
        opacity: 1;
        top: 41px;
        height: 17px;
        width: 38px;
        left: 6px;
    }
    50% {
        opacity: 1;
        top: 41px;
        height: 17px;
        width: 38px;
        left: 6px;
    }
    90% {
        opacity: 1;
        top: 41px;
        height: 0;
        width: 10px;
        left: 20px;
    }
}
@keyframes sandDeplete {
    0% {
        opacity: 0;
        top: 45px;
        height: 17px;
        width: 38px;
        left: 6px;
    }
    1% {
        opacity: 1;
        top: 45px;
        height: 17px;
        width: 38px;
        left: 6px;
    }
    24% {
        opacity: 1;
        top: 45px;
        height: 17px;
        width: 38px;
        left: 6px;
    }
    25% {
        opacity: 1;
        top: 41px;
        height: 17px;
        width: 38px;
        left: 6px;
    }
    50% {
        opacity: 1;
        top: 41px;
        height: 17px;
        width: 38px;
        left: 6px;
    }
    90% {
        opacity: 1;
        top: 41px;
        height: 0;
        width: 10px;
        left: 20px;
    }
}
/* end */


.Space_Arrow_top{
    padding: 80px 0px 180px !important;
}
.Space_Arrow_bottom{
    padding: 180px 0px 80px !important;
}
@media only screen and (max-width: 760px) {
    .Space_Arrow_top{
        padding: 80px 0px 150px !important;
    }
    .Space_Arrow_bottom{
        padding: 150px 0px 80px !important;
    }
}