.MakeAppointment{    
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    cursor: pointer;
}
.MakeAppointment .btn {
    padding: 10px 20px;
    border-radius: 6px;
}

.MakeAppointment .color_1{
    background-color: #6B7D5C !important;
    color: #E7E3CE !important;
}
.MakeAppointment .color_2{
    background-color: #E7E3CE !important;
    color: #6B7D5C !important;
}