.PopUp .PopUp_TextFooter {
    width: 800px;
    font-family: 'inter';
    margin-bottom: 40px;
}

@media only screen and (max-width: 1000px) {
    .PopUp .PopUp_TextFooter {
        width: 90%;
    }
}