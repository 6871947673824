.ModelPage_Arrow {
    width: 100%;
    height: auto;
    padding: 80px 0px;
    background-color: transparent;
    color: #E7E3CE;
    font-family: 'montserrat';
    transition: 0.5s;
    
    position: absolute;
    z-index: 4;
    margin-top: -174px;
}
.ModelPage_Arrow .container {
    width: calc(100% - 80px);
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
}

.ModelPage_Arrow .div_arrow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* width: 172px; */
    height: 190px;
    position: relative;
}
.ModelPage_Arrow .div_arrow .div_triangle{
    width: 40px;
    height: 100%;
    position: relative;
}
.ModelPage_Arrow .div_arrow .div_triangle .top{
    width: 0;
    height: 0;
    border-bottom: 95px solid transparent;
    border-right: 40px solid #ABA8B2;
}
.ModelPage_Arrow .div_arrow .div_triangle .bottom{
    width: 0;
    height: 0;
    border-top: 95px solid transparent;
    border-right: 40px solid #ABA8B2;
}
.ModelPage_Arrow .div_arrow .show_right {
    border-top: 95px solid transparent;
    border-bottom: 95px solid transparent;
    border-left: 40px solid #4C4B63;
}
.ModelPage_Arrow .div_arrow .top{
    width: 0;
    height: 0;
    border-bottom: 95px solid transparent;
    border-right: 40px solid #ABA8B2;
}
.ModelPage_Arrow .div_arrow .bottom{
    width: 0;
    height: 0;
    border-top: 95px solid transparent;
    border-right: 40px solid #ABA8B2;
}
.ModelPage_Arrow .div_arrow .div_text{
    width: 140px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ModelPage_Arrow .div_arrow .div_text .title{
    font-size: 28px;
    line-height: 28px;
    font-weight: 600;
    text-align: left;
    width: -webkit-fill-available;
    padding-left: 10px;
}
.ModelPage_Arrow .div_arrow .div_text .text{
    font-size: 12px;
    line-height: 14px;
    width: -webkit-fill-available;
    padding-left: 10px;
}

.ModelPage_Arrow .div_arrow .triangle_left{
    left: 0px;
    height: 0;
    border-top: 95px solid transparent;
    border-bottom: 95px solid transparent;
    border-left: 46px solid #4C4B63;
    z-index: 1
}
.ModelPage_Arrow .div_arrow .triangle_right{
    border-top: 95px solid transparent;
    border-bottom: 95px solid transparent;
    border-left: 40px solid #4C4B63;
}

@media only screen and (max-width: 760px) {
    .ModelPage_Arrow{
        margin-top: -150px;
    }
    .ModelPage_Arrow .div_arrow{
        height: 140px;
    }
    .ModelPage_Arrow .div_arrow .div_text{
        gap: 10px;
    }
    .ModelPage_Arrow .div_arrow .div_text .title{
        font-size: 16px;
        line-height: 16px;
    }
    .ModelPage_Arrow .div_arrow .div_triangle{
        width: 20px;
    }
    .ModelPage_Arrow .div_arrow .div_triangle .top{
        border-bottom: 70px solid transparent;
        border-right: 20px solid #ABA8B2;
    }
    .ModelPage_Arrow .div_arrow .div_triangle .bottom{
        border-top: 70px solid transparent;
        border-right: 20px solid #ABA8B2;
    }
    .ModelPage_Arrow .div_arrow .show_right{
        border-top: 70px solid transparent;
        border-bottom: 70px solid transparent;
        border-left: 20px solid #4C4B63;
    }
}
@media only screen and (max-width: 600px) {
    .ModelPage_Arrow .container{
        width: calc(100% - 40px);
    }
    .ModelPage_Arrow .div_arrow .div_text{
        width: 90px;
    }
    .ModelPage_Arrow .div_arrow .div_text .title{
        font-size: 12px;
        line-height: 10px;
        padding-left: 2px;
    }
    .ModelPage_Arrow .div_arrow .div_text .text{
        font-size: 7px;
        line-height: 8px;
        padding-left: 2px;
    }
}