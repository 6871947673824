.ModelPage_TextPhoto {
    width: 100%;
    height: auto;
    padding: 80px 0px 0px;
    color: #6B7D5C;
    background-color: #F5F5F5;
    font-family: 'montserrat';
    transition: 0.5s;
    position: relative;
}
.img_fixed {
    padding: 0px;
    height: 768px;
    min-height: 768px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.img_fixed .color_bg_img{
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.6;
}
.ModelPage_TextPhoto .container {
    width: calc(100% - 80px);
    height: auto;
    display: flex;
    transition: 0.5s;
    position: relative;
    z-index: 2;
}

.ModelPage_TextPhoto .text_bg_left{
    height: 100%;
    align-items: center;
    justify-content: start;
}

.ModelPage_TextPhoto .text_bg_right{
    height: 100%;
    align-items: center;
    justify-content: end;
}

.ModelPage_TextPhoto .show_img_side{
    gap: 40px;
}
.ModelPage_TextPhoto .show_img_bg{
    height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ModelPage_TextPhoto .div_img{
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.ModelPage_TextPhoto .div_img .img{
    width: 100%;
    height: fit-content;
    display: flex;
}
.ModelPage_TextPhoto .div_text{
    width: 50%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 30px;
}
.ModelPage_TextPhoto .bg_right {
    width: 100%;
    margin-bottom: 0px;
}
.ModelPage_TextPhoto .bg_left {
    width: 100%;
    margin-bottom: 0px;
}
.ModelPage_TextPhoto .div_text .title{
    text-align: right;
    font-size: 32px;
    line-height: 32px;
    font-weight: 900;
}
.ModelPage_TextPhoto .div_text .text{
    text-align: right;
    font-size: 14px;
    line-height: 16px;
}

@media only screen and (max-width: 960px) {
    .ModelPage_TextPhoto .container{
        flex-direction: column;
    }
    .ModelPage_TextPhoto .div_text{
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 40px 0px;
    }
    .ModelPage_TextPhoto .div_text .text{
        text-align: center !important;
    }
    .ModelPage_TextPhoto .div_text .title{
        text-align: center !important;
    }
    .ModelPage_TextPhoto .div_img{
        width: 100%;
        order: 2;
    }
    .ModelPage_TextPhoto .div_img .img{
        width: 50%;
    }
    .ModelPage_TextPhoto .show_img_bg{
        width: 90%;
    }
    .ModelPage_TextPhoto .div_text .text p{
        text-align: center !important;
    }
    .img_fixed{
        height: auto;
        min-height: max-content;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 580px) {
    .ModelPage_TextPhoto .show_img_bg {
        width: 100%;
    }
    .ModelPage_TextPhoto .container{
        width: calc(100% - 40px);
    }
    .ModelPage_TextPhoto .div_text .title{
        font-size: 22px;
        line-height: 22px;
    }
}
