.ModelPage_TextTitle {
    width: 100%;
    height: auto;
    padding: 80px 0px;
    background-color: #F5F5F5;
    font-family: 'montserrat';
    transition: 0.5s;
    position: relative;
}
.ModelPage_TextTitle .space_top{
    margin-top: 40px;
}

.ModelPage_TextTitle .container {
    width: calc(100% - 80px);
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 60px;
    transition: 0.5s;
}
.ModelPage_TextTitle .title{
    text-align: left;
    font-weight: 900;
    font-size: 72px;
    line-height: 70px;
    color: #6b7d5c;
}
.ModelPage_TextTitle .text{
    text-align: left;
    font-size: 16px;
    line-height: 20px;
}
@media only screen and (max-width: 850px) {
    .ModelPage_TextTitle .container{
        flex-direction: column;
    }
    .ModelPage_TextTitle .title{
        text-align: center;
    }
}
@media only screen and (max-width: 820px) {
     .ModelPage_TextTitle .container {
        width: calc(86% - 60px);
    }
}
@media only screen and (max-width: 690px) {
    .ModelPage_TextTitle .container{
        width: calc(100% - 80px);
    }
}
@media only screen and (max-width: 580px) {
    .ModelPage_TextTitle .container{
        width: calc(100% - 40px);
        gap: 30px;
    }
    .ModelPage_TextTitle .title{
        font-size: 42px;
        line-height: 42px;
    }
}
@media only screen and (max-width: 460px) {
    .ModelPage_TextTitle .container{
        align-items: center;
    }
}