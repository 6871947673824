.ModelPage_Home {
    width: 100%;
    height: auto;
    padding: 0px;
    color: #E7E3CE;
    background-color: #F5F5F5;
    font-family: 'montserrat';
    transition: 0.5s;
    position: relative;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.ModelPage_Home .div_space_{
    position: absolute;
    bottom: 0;
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.ModelPage_Home .div_space_ .left{
    height: 100%;
    width: 50%;
    border-radius: 100% 0% 0 0;
    background-color: #F5F5F5;
}
.ModelPage_Home .div_space_ .right{
    height: 100%;
    width: 50%;
    border-radius: 0% 100% 0 0;
    background-color: #F5F5F5;
}
.ModelPage_Home .color_bg_img{
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgb(40 67 18 / 100%);
    opacity: 0.7;
}

.ModelPage_Home .container {
    width: calc(60% - 80px);
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    transition: 0.5s;
    position: relative;
    z-index: 2;
    gap: 40px;
}
.ModelPage_Home .show_div_video{
    width: calc(80% - 80px);
}
.ModelPage_Home .div_title{
    font-size: 52px;
    line-height: 52px;
    font-weight: 900;
    text-align: center;
    padding-top: 200px;
    transition: 0.5s;
}
.ModelPage_Home .div_subtitle{
    font-size: 12px;
}
.ModelPage_Home .div_text{
    font-size: 16px;
    line-height: 20px;
    text-align: center;
}
.ModelPage_Home .div_input{
    display: flex;
    align-items: center;
    justify-content: center;
    
    flex-grow: 1;
}
.ModelPage_Home .div_input input::placeholder {
    color: #E7E3CE;
}
.ModelPage_Home .div_input input{
    color: #E7E3CE;
    border: 2px solid #E7E3CE;
    border-radius: 6px;
    font-size: 14px;
    padding: 10px;
    font-family: 'inter';
    outline: none;
    width: 400px;
    background-color: transparent;
}
.ModelPage_Home .div_input .btn_save{
    color: #626262;
    background-color: #E7E3CE;
    padding: 10px 20px;
    position: relative;
    left: -6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    cursor: pointer;
}
.ModelPage_Home .div_video {
    padding-top: 20px;
    height: auto;
    width: 100%;
    aspect-ratio: 1.777;
}
.ModelPage_Home iframe{
    border: none;
}
.ModelPage_Home .div_show_error{
    color: #e34545;
    padding-top: 12px;
}

@media only screen and (max-width: 1120px) {
    .ModelPage_Home .container {
        width: calc(80% - 80px);
    }
}
@media only screen and (max-width: 860px) {
    .ModelPage_Home .div_title{
        padding-top: 160px;
    }
}
@media only screen and (max-width: 760px) {
    .ModelPage_Home .container {
        width: calc(100% - 80px);
    }
    .ModelPage_Home .div_input input{
        width: calc(80% - 20px);
        font-size: 12px;
    }
    .ModelPage_Home .div_input .btn_save{
        padding: 9px 20px;
    }
    .ModelPage_Home .div_title{
        font-size: 38px;
        line-height: 40px;
    }
    .ModelPage_Home .div_subtitle{
        text-align: center;
    }
    .ModelPage_Home .div_text{
        font-size: 14px;
        line-height: 18px;
    }
}
@media only screen and (max-width: 580px) {
    .ModelPage_Home .container {
        width: calc(100% - 40px);
    }
    .ModelPage_Home .div_title{
        padding-top: 160px;
    }
}
@media only screen and (max-width: 500px) {
    .ModelPage_Home .div_space_ {
        height: 140px;
    }
}